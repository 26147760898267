
import ColourSelector from '@/components/collections/my-website/ColourSelector.vue'
import { FormPage } from '@/mixins'
import { DropdownListItem } from 'tradingmate-modules/src/forms'
import BusinessBranchStates from 'tradingmate-modules/src/models/api/businesses/BusinessBranchStates'
import MyWebsiteV3MastheadAlignmentHorizontal from 'tradingmate-modules/src/models/api/my-websites/enums/MyWebsiteV3MastheadAlignmentHorizontal'
import MyWebsiteV3MastheadAlignmentVertical from 'tradingmate-modules/src/models/api/my-websites/enums/MyWebsiteV3MastheadAlignmentVertical'
import { HttpError, Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'
import { FieldCheckbox, FieldSelect, FieldText, FieldTextarea } from '@/components/forms/fields'
import CategoryEditor from '@/components/forms/fields/FieldCategoryEditor.vue'
import ContactMethodEditor from '@/components/collections/business/ContactMethodEditor.vue'
import SocialLinkEditor from '@/components/collections/business/SocialLinkEditor.vue'
import OpeningHoursEditor from '@/components/collections/business/OpeningHoursEditor.vue'
import ImageGallery from '@/components/inputs/ImageGallery.vue'
import { Config } from 'tradingmate-modules/src/environment'
import MyWebsiteV3Model from 'tradingmate-modules/src/models/api/my-websites/MyWebsiteV3Model'
import MyWebsiteRegisterRequest from 'tradingmate-modules/src/models/api/my-websites/MyWebsiteRegisterRequest'
import AttachedObjectType from 'tradingmate-modules/src/models/api/files/AttachedObjectType'
import { FileAttachmentType } from 'tradingmate-modules/src/models/api/files'
import Copyable from '@/components/inputs/Copyable.vue'
import { InputText } from '@/components/inputs'
import MyWebsiteImages from '@/components/inputs/website-images/MyWebsiteImages.vue'

@Component({
  components: {
    MyWebsiteImages,
    InputText,
    FieldText,
    FieldSelect,
    FieldCheckbox,
    FieldTextarea,
    SocialLinkEditor,
    ContactMethodEditor,
    CategoryEditor,
    OpeningHoursEditor,
    ColourSelector,
    ImageGallery,
    Copyable
  }
})

export default class MyWebsiteEdit extends Mixins(FormPage) {
  private branchId = '';

  get previewLink (): string {
    return `${Config.WebsiteUrls.Console}/my-websites/preview/${this.branchId}`
  }

  private iFrameSource = ''

  // get link (): string {
  // const link = Config.API.Base
  //   return `${Config.API.Base}/my-websites/preview/${this.branchId}`
  // }

  async mounted (): Promise<void> {
    this.branchId = this.$route.params.id
    await this.GetWebsiteV3()
    this.UpdateIFrameSource()
  }

  private isGettingWebsite = false
  private website: MyWebsiteV3Model | null = null

  async GetWebsiteV3 (): Promise<void> {
    if (!this.branchId) return
    this.isGettingWebsite = true
    await Services.API.MyWebsites.GetMyWebsite(this.branchId)
      .then((result) => {
        this.website = result
      })
      .catch((error) => {
        this.httpError = error
      }).finally(() => {
        this.isGettingWebsite = false
      })
  }

  private isUpdating = false
  private websiteErrors: HttpError | null = null
  private updateDebounce: number | undefined = undefined
  WebsiteUpdate (): void {
    console.log('website update')
    window.clearTimeout(this.updateDebounce)
    this.updateDebounce = window.setTimeout(() =>
    {
      this.isUpdating = true
      this.websiteErrors = null
      if (this.website == null) return
      Services.API.MyWebsites.UpdateWebsite(this.website.WebsiteId, this.website)
        .then(() => {
          this.UpdateIFrameSource()
        }).catch((error) => {
          this.websiteErrors = error
        }).finally(() => {
          this.isUpdating = false
        })
    }, 1000)
  }

  // iframe
  UpdateIFrameSource (): void {
    const preview = this.$refs.preview as HTMLIFrameElement
    if (this.branchId == null) return
    Services.API.MyWebsites.GetWebsitePreview(this.branchId)
      .then((response) => {
        if (!preview.contentWindow) return
        // console.log(response)
        preview.contentWindow.document.open()
        preview.contentWindow.document.write(response)
        preview.contentWindow.document.close()
      })
  }

  private mastheadAlignmentHorizontalItems: DropdownListItem[] = [
    { Key: MyWebsiteV3MastheadAlignmentHorizontal[0], Label: 'Centre', Value: MyWebsiteV3MastheadAlignmentHorizontal.Centre },
    { Key: MyWebsiteV3MastheadAlignmentHorizontal[1], Label: 'Left', Value: MyWebsiteV3MastheadAlignmentHorizontal.Left },
    { Key: MyWebsiteV3MastheadAlignmentHorizontal[2], Label: 'Right', Value: MyWebsiteV3MastheadAlignmentHorizontal.Right }
  ]

  private mastheadAlignmentVerticalItems: DropdownListItem[] = [
    { Key: MyWebsiteV3MastheadAlignmentVertical[0], Label: 'Centre', Value: MyWebsiteV3MastheadAlignmentVertical.Centre },
    { Key: MyWebsiteV3MastheadAlignmentVertical[1], Label: 'Top', Value: MyWebsiteV3MastheadAlignmentVertical.Top },
    { Key: MyWebsiteV3MastheadAlignmentVertical[2], Label: 'Bottom', Value: MyWebsiteV3MastheadAlignmentVertical.Bottom }
  ]

  private stateItems: DropdownListItem[] = [
    { Key: BusinessBranchStates.Queensland, Label: 'Queensland', Value: BusinessBranchStates.Queensland },
    { Key: BusinessBranchStates.NewSouthWales, Label: 'New South Wales', Value: BusinessBranchStates.NewSouthWales },
    { Key: BusinessBranchStates.Victoria, Label: 'Victoria', Value: BusinessBranchStates.Victoria },
    { Key: BusinessBranchStates.Tasmania, Label: 'Tasmania', Value: BusinessBranchStates.Tasmania },
    { Key: BusinessBranchStates.SouthAustralia, Label: 'South Australia', Value: BusinessBranchStates.SouthAustralia },
    { Key: BusinessBranchStates.WesternAustralia, Label: 'Western Australia', Value: BusinessBranchStates.WesternAustralia },
    { Key: BusinessBranchStates.NorthernTerritory, Label: 'Northern Territory', Value: BusinessBranchStates.NorthernTerritory },
    { Key: BusinessBranchStates.AustralianCapitalTerritory, Label: 'ACT', Value: BusinessBranchStates.AustralianCapitalTerritory }
  ]

  // Background stuff
  private hasCustomBackgroundImage = true;
  private objectType = AttachedObjectType.MyWebsite;
  private branchObjectType = AttachedObjectType.BusinessBranch
  private fileAttachmentType = FileAttachmentType.Background;

  // Image stuff
  private featureRowAttachmentType = FileAttachmentType.FeatureRow
  private addInfoAttachmentType = FileAttachmentType.AdditionalInfo
  private licenseAttachmentType = FileAttachmentType.License
  private galleryAttachmentType = FileAttachmentType.ImageGalleryItem

  // Domain shit
  private domainCheckInput = '';
  private checkingDomain = false;
  private domainAvailable = false;

  HandleDomainCheck (): void {
    this.checkingDomain = true
    Services.API.MyWebsites.CheckDomainAvailability(this.domainCheckInput)
      .then((responce) => {
        console.log('responce')
        console.log(responce)
        this.domainAvailable = responce.Available
      })
      .finally(() => { this.checkingDomain = false })
  }

  changePrimaryColour (colour: string): void {
    if (!this.website) return
    this.website.PrimaryColour = colour
    this.WebsiteUpdate()
  }

  changeSecondaryColour (colour: string): void {
    console.log('change secoday colour')
    if (!this.website) return
    this.website.SecondaryColour = colour
    this.WebsiteUpdate()
  }

  private registeringWebsite = false
  handleCheckout (): void {
    this.registeringWebsite = true
    const request: MyWebsiteRegisterRequest = { Domain: this.domainCheckInput }
    Services.API.MyWebsites.RegisterWebsite(request)
      .then((response) => {
        window.open(response.Url)
      }).catch(() => {
        //
      }).finally(() => {
        this.registeringWebsite = false
      })
  }

  // getMyWebsite (): void {
  //   Services.API.MyWebsites.GetWebsite(this.branch.BusinessBranchId).then((returnModel) => {
  //     this.website = return
  //   })
  // }

  // Website Settings
  // private websiteSetting: WebsiteSettings | null = null;

  // selectedCategory (selection: CategoryAutocompleteResult): void {
  //   if (!this.branch) return
  //   Services.API.Businesses.AddBusinessBranchCategory(this.branch.BusinessBranchId, selection.Category.CategoryId)
  //     .then((category) => {
  //       if (!this.branch) return
  //       this.branch.Categories.push(category)
  //     // this.value.push(category)
  //     })
  // }

  // removeCategory (categoryId: string): void {
  //   if (!this.branch) return
  //   Services.API.Businesses.DeleteBusinessBranchCategory(this.branch.BusinessBranchId, categoryId)
  //     .then(() => { if (!this.branch) return; this.branch.Categories.splice(this.branch.Categories.findIndex((val: CategoryModel) => val.CategoryId === categoryId), 1) })
  // }

  // addSocialLink () {
  //   const socialLinkEditor = (this.$refs.socialLinkEditor as SocialLinkEditor)
  //   socialLinkEditor.socialMediaModalActive = true
  // }
  //
  // addContactMethod () {
  //   const contactMethodEditor = (this.$refs.contactMethodEditor as ContactMethodEditor)
  //   contactMethodEditor.modalActive = true
  // }
  //
  // addOpeningTime () {
  //   const hoursEditor = (this.$refs.hoursEditor as OpeningHoursEditor)
  //   hoursEditor.CreateOpeningTime()
  // }
}

