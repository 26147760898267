
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class ColourSelector extends Vue {
  @Prop({ required: true })
  private readonly text!: string;

  @Prop({ required: true })
  private readonly colour!: string;

  @Prop({ default: false })
  private readonly selectedTextWhite!: boolean;

  @Prop({ required: true })
  private readonly selected!: boolean;

  handleClicked (): void {
    this.$emit('clicked')
  }

  get textColour (): string {
    if (this.colour === 'white') return 'black'
    if (!this.selected) return this.colour
    if (this.selectedTextWhite) {
      return 'white'
    } else {
      return 'black'
    }
  }

  get backgroundColour (): string {
    // if (this.colour === 'white') return 'black'
    if (this.selected)
    {
      return this.colour
    }

    else return 'white'
  }

  get borderColour (): string {
    return 'black'
    // return this.selectedTextWhite ? 'white' : 'black'
  }
}

