
import { Field } from '@/mixins'
import { Component, Prop, Mixins } from 'vue-property-decorator'

@Component({
  components: {
  },
  inheritAttrs: true
})

export default class FieldTextarea extends Mixins(Field) {
  // v-model on this to access this
  @Prop({ required: true })
  private readonly value!: string;

  @Prop({ default: 32000 })
  private readonly maxLength!: number

  @Prop({ default: 4 })
  private readonly rows!: number;

  mounted (): void {
    this.setHeight()
  }

  setHeight (): void {
    const textarea = this.$refs.textarea as HTMLTextAreaElement
    textarea.style.height = 'auto'
    textarea.style.height = `${textarea.scrollHeight}px`
  }
}

