
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { Field } from '@/mixins'

@Component({
  components: {
  }
})

export default class FieldText extends Mixins(Field) {
  // v-model used on component will bind to this value
  @Prop({ required: true })
  private readonly value!: string;

  @Prop({ default: 'text' })
  private readonly type!: 'text' | 'email' | 'tel' | 'password' | 'search' | 'url';

  @Prop({ default: 100 })
  private readonly maxLength!: number

  @Prop({ default: false })
  private readonly noMargin!: boolean;
}

