
import { Field } from '@/mixins'
import { DropdownListItem } from 'tradingmate-modules/src/forms'
import { Component, Prop, Mixins } from 'vue-property-decorator'

@Component
export default class FieldSelect extends Mixins(Field) {
  @Prop({ default: '' })
  public value!: number | string;

  @Prop({ required: true })
  private readonly items!: DropdownListItem[];

  get hasValue (): boolean {
    if (this.value === null) return false
    if (this.value === '') return false
    return true
  }
}
