
import { Component, Prop, PropSync, Mixins } from 'vue-property-decorator'
import flatpickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { Field } from '@/mixins'

export interface FieldDateTimeOptions {
  defaultDate?: string;
  defaultHour?: number;
  defaultMinute?: number;
  maxDate?: string | Date;
  minDate?: string | Date;
}

@Component({
  components: {
    flatpickr
  }
})

export default class FieldDateTime extends Mixins(Field) {
  // :value.sync to model to this
  @PropSync('valueSync', { default: null })
  private value!: string | null;

  @Prop({ default: 'date' })
  private readonly type!: 'date' | 'time' | 'datetime';

  @Prop({ default: () => ({}) })
  private readonly config!: FieldDateTimeOptions;

  get totalConfig (): Record<string, unknown> {
    switch (this.type) {
      case 'date':
        return {
          altInput: true,
          dateFormat: 'Z',
          ...this.config
        }
      case 'time':
        return {
          altInput: true,
          dateFormat: 'Z',
          enableTime: true,
          noCalendar: true,
          altFormat: 'h:i K',
          ...this.config
        }
      case 'datetime':
        return {
          altInput: true,
          dateFormat: 'Z',
          enableTime: true,
          altFormat: 'd M y h:i K',
          ...this.config
        }
    }
  }
}

