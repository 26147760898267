
import { Field } from '@/mixins'
import { Component, Prop, Mixins } from 'vue-property-decorator'

@Component
export default class FieldCheckbox extends Mixins(Field) {
  @Prop({ required: true })
  private value!: boolean;
}

