
import { FormPage } from '@/mixins'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { FileAttachmentType } from 'tradingmate-modules/src/models/api/files'
import ImageGallery from '@/components/inputs/ImageGallery.vue'
import MyWebsiteImage from '@/components/inputs/website-images/MyWebsiteImage.vue'
import AttachedObjectType from 'tradingmate-modules/src/models/api/files/AttachedObjectType'

@Component({
  components: {
    MyWebsiteImage,
    ImageGallery
  }
})

export default class MyWebsiteImages extends Mixins(FormPage) {
  @Prop({ required: true })
  private readonly websiteId!: string;

  @Prop({ required: true })
  private readonly branchId!: string;

  private FileAttachmentType = FileAttachmentType
  private AttachedObjectType = AttachedObjectType

  onImagesUpdated (): void {
    this.$emit('imageUpdated')
    console.log('on images updated')
  }
}

