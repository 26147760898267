
import { GetFileModel } from 'tradingmate-modules/src/models/api/files'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FieldFileDrop extends Vue {
  @Prop({ required: true })
  private readonly label!: string;

  @Prop()
  private readonly hint!: string;

  @Prop({ default: 'Click or Drag Files Here' })
  private readonly dropzoneTitle!: string;

  @Prop()
  private readonly dropzoneHint!: string;

  @Prop({ default: 'file_upload' })
  private readonly dropzoneIcon!: string;

  @Prop({ default: 'image/png,image/jpg,image/jpeg' })
  private readonly mimes!: string;

  @Prop()
  private readonly multiple!: boolean;

  isDraggedOver = false

  files: GetFileModel[] = []

  handleDragOver (evt: Event): void {
    evt.preventDefault()

    this.isDraggedOver = true
  }

  handleDrop (evt: DragEvent): void {
    evt.preventDefault()

    this.isDraggedOver = false

    if (evt.dataTransfer?.files.length) {
      this.uploadFile(evt.dataTransfer.files[0])
    }
  }

  uploadFile (file: File): void {
    Services.API.Files.Upload(file, true).then((result) => {
      this.files.push(result)

      this.$emit('input', this.files.map((f) => f.FileId).join(','))
    })
  }

  private fi: HTMLInputElement | null = null

  selectFile (): void {
    if (this.fi == null) {
      this.fi = document.createElement('input')
      this.fi.type = 'file'
      this.fi.accept = this.mimes
      this.fi.onchange = () => {
        if (!this.fi?.files) return
        if (this.fi.files?.length > 0) {
          this.uploadFile(this.fi.files[0])
          this.fi.value = ''
        }
      }
    }

    this.fi.click()
  }

  removeFile (file: GetFileModel): void {
    const idx = this.files.findIndex((f) => f.FileId === file.FileId)

    if (idx !== -1) this.files.splice(idx, 1)
  }
}
